export default [
  {
    path: '/policy/list',
    name: 'allPolicy',
    component: () => import('@/views/policy/allPolicy'),
    meta: {
      keepAlive: false,
      // title:'All Policy'
    }
  },
  {
    path: '/policy/draft',
    name: 'draft',
    component: () => import('@/views/policy/draft'),
    meta: {
      keepAlive: false,
      title:'All Quotation'
    }
  },
  {
    path: '/ticket/list',
    name: 'ticketList',
    component: () => import('@/views/ticket/list'),
    meta: {
      keepAlive: false,
    }
  },
  {
    path: '/policy/info',
    name: 'policyInfo',
    component: () => import('@/views/policy/policyInfo'),
    meta: {
      keepAlive: false,
    }
  }
]
